<template>
  <div class="d-flex align-items-center">
    <div class="manual-icon">
      <font-awesome-icon
        icon="book"
        class="cursor-pointer"
        title="User Manual Page"
        @click="$router.push('/setting/user-manual')"
      ></font-awesome-icon>
    </div>
    <div class="text-bold color-black py-2 px-3" style="color: #333">
      {{ name }}
    </div>
    <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="py-0"
    >
      <template #toggler>
        <CHeaderNavLink>
          <div class="c-avatar">
            <img src="/img/avatars/9.png" class="c-avatar-img" />
          </div>
        </CHeaderNavLink>
      </template>

      <CDropdownItem @click="logout">
        <CIcon name="cil-lock-locked" />Logout
      </CDropdownItem>
    </CDropdown>

    <ModalConfirm
      id="logout"
      ref="confirmModal"
      text="You want to log out"
      @handler="deleteMsg"
    />
  </div>
</template>

<script>
import ModalConfirm from "@/components/modal/ModalConfirm";
export default {
  components: {
    ModalConfirm,
  },
  name: "TheHeaderDropdownAccnt",
  data() {
    return {
      name: "",
    };
  },
  created() {
    this.getName();
  },
  methods: {
    getName() {
      let staffID = this.$store.state.permission.Permission.name.staff_id
        ? `(${this.$store.state.permission.Permission.name.staff_id})`
        : "";
      let name = `${this.$store.state.permission.Permission.name} ${staffID}`;
      this.name = name;
    },
    async deleteMsg(value) {
      let data = null;
      if (this.$cookies.get("back_office_admin_registerTypeId") == 6) {
        await this.$store.dispatch("getLogoutAzure");
        data = this.$store.state.setting.stateLogoutAzure;
      } else {
        await this.$store.dispatch("getLogout");
        data = this.$store.state.setting.stateLogout;
      }
      if (data.result === 1) {
        this.getLogout(value);
      }
    },
    getLogout(value) {
      if (value === true) {
        this.$cookies.remove("back_office_admin_token");
        this.$cookies.remove("permission_menu_admin");
        localStorage.removeItem("back_office_admin_permission");
        this.$cookies.remove("back_office_admin_user_guid");
        this.$cookies.remove("back_office_admin_email");
        this.$cookies.remove("back_office_admin_userName");
        this.$cookies.remove("back_office_admin_registerTypeId");
        window.location.href = "/";
      }
    },
    logout() {
      this.$refs.confirmModal.show();
    },
  },
};
</script>

<style scoped>
.c-icon {
  margin-right: 0.3rem;
}
.manual-icon {
  background-color: white;
  color: var(--primary-color);
  border-radius: 50%;
  padding: 0.5rem 0.75rem;
  box-shadow: 0px 0px 9px -5px;
}
</style>
